.row {
	display: flex;
	justify-content: space-between;
	padding: 8px 0;
	margin: 5px 0 0 0;
	border-radius: 6px;
	border-bottom: 1px solid var(--fielddisabled-color);
}

@media (max-width: 991.5px) {
	.row {
		margin: 5px -16px 0 -16px;
		padding: 11px 16px;
	}
}

@media (min-width: 991.5px) {
	.row:hover {
		background: var(--secondary-color);
	}
}

@media screen and (max-width: 991.5px) {
	.row {
		user-select: none;
		-webkit-user-select: none; /* Safari */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
	}
}


.checked {
	background: var(--secondary-color);
}

.checkbox {
	margin: 0px 19px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.column__name {
	display: flex;
	align-items: center;
	flex-basis: 35%;
	overflow: hidden;
	white-space: nowrap;
	padding-right: 10px;
}

.column__name.invite {
	flex-basis: 35%;
}

.column__name.teams {
	flex-basis: 50%;
}

@media (max-width: 991.5px) {
	.column__name {
		flex-basis: 75%;
	}
	.column__name.teams {
		flex-basis: 91%;
	}
}

.info {
	max-width: 100%;
	overflow: hidden;
}

.info>p, .info>a {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	text-decoration: none;
	cursor: pointer;
}

.avatar__wrap {
	position: relative;
	margin: 0px 8px 0px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.avatar {
	flex-basis: 40px;
	flex-shrink: 0;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
}

.avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.avatar__icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: 21px;
	color: var(--field-color);
	background: var(--secondary-color);
}

.avatar i,
.avatar__wrap i {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	font-size: 16px;
	font-weight: 900;
	background-color: var(--primary-color);
	color: var(--textlight-color);
	border-radius: 50%;
}

.info p:nth-child(1) {
	font-weight: 500;
	color: var(--textdark-color);
}

.role {
	display: flex;
	flex-basis: 13%;
	align-items: center;
}

.role.invite {
	flex-basis: 30%;
}

.column__text {
	display: flex;
	flex-basis: 13%;
	justify-content: flex-start;
	align-items: center;
}

.column__text.invite {
	flex-basis: 30%;
}

.activity {
	flex-basis: 20%;
}


.user {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%; /* Налаштуй ширину під дизайн */
    display: block;
}

.user p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    display: block;
}

.users,
.team__lessons {
	flex-basis: 20%;
}
.team__info {
	max-height: 40px;
	overflow: hidden;
	flex-grow: 1;
}

.team__info p {
	white-space: normal;
	overflow-x: visible;
	overflow-y: hidden;

}