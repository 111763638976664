.avatar__wrap {
	position: relative;
	margin: 0px 8px 0px 0px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.avatar {
	height: 40px;
	width: 40px;
	flex-basis: 40px;
	flex-shrink: 0 !important;
	border-radius: 50%;
	border: 0.5px solid var(--fielddisabled-color);
	overflow: hidden;
}

.avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.avatar__icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: 21px;
	color: var(--field-color);
	background: var(--secondary-color);
}

.avatar i,
.avatar__wrap i {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	font-size: 16px;
	font-weight: 900;
	background-color: var(--primary-color);
	color: var(--textlight-color);
	border-radius: 50%;
}