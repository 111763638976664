.name >div:hover {
	text-decoration: underline;
}

.row {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
}

.name p,
.name a {
	
	overflow: hidden;
	text-overflow: ellipsis;
}

.name p {
	color: var(--textdark-color);
	font-weight: 500;
}

.name a {
	color: var(--fieldhover-color)
}