.column {
	display: flex;
	align-items: center;
}
.avatar__wrap {
	position: relative;
	margin: 0px 8px 0px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.avatar {
	flex-basis: 40px;
	flex-shrink: 0;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
}

.avatar img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.avatar__icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-size: 21px;
	color: var(--field-color);
	background: var(--secondary-color);
}

.team__info a {
	font-weight: 500;
	color: var(--textdark-color);
	text-decoration: none;
}

.team__info {
	max-height: 40px;
	overflow: hidden;
	padding-right: 30px;
}

.team__info p {
	white-space: normal;
	overflow-x: visible;
	overflow-y: hidden;

}