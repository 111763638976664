.buttonText__container{
	display: inline-block;
	vertical-align: middle;
	position: relative;
	width: 100%;
}

.hidden__text {
	position: absolute;
	white-space: nowrap;
	visibility: hidden;
	height: auto;
	width: auto;
	overflow: visible;
	white-space: nowrap;
}

.button {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	max-width: max-content;
	width: 100%;
	overflow: hidden;
	padding-right: 30px;
	margin-right: 8px;
}

.button>i {
	margin: 0px 5px 0px 0px;
	font-size: 24px;
	color: var(--fieldhover-color);
	transition: all 0.2s ease-in-out;
	align-items: center;
	width: 24px; /* Фіксований розмір іконки */
    height: 24px;
}

.button h3 {
	font-weight: 600;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	/*max-width: calc(100vw - 630px) !important;*/
	/*width: calc(100%) !important;*/
	max-width: 100%;
	width: 100%;
	align-items: center;
}

.hidden__text {
	font-size: 28px;
}

/* .button h3 > span {
	color: var(--fieldhover-color);
	font-size: 20px;
} */

.button:hover>i {
	transform: scale(1.2);
}

.button:active > i {
	color: var(--textdark-color);
}

@media (min-width: 1023.5px) {
	.button h3 {
		color: var(--fieldhover-color);
		font-size: 20px;
	}
}

/* @media (min-width: 991.5px) {
	.button h3,
	.hidden__text {
		font-size: 28px;
	}
}

@media (min-width: 1660px) {
	.button h3,
	.hidden__text {
		font-size: 30px;
	}
} */

/*@media (max-width: 1200.5px) {
	.button h3 {
		max-width: calc(100vw - 475px) !important;
	}
}

@media (max-width: 991.5px) {
	.button h3 {
		max-width: calc(100vw - 250px) !important;
	}
}

@media (max-width: 540.5px) {
	.button h3 {
		max-width: calc(100vw - 230px) !important;
	}
}*/