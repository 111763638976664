.user {
	padding: 8px 0px 8px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	width: 100%;
	overflow: hidden;
}

.user>i {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: var(--secondary-color);
	font-size: 14px;
	font-weight: 900;
	color: var(--field-color);
	cursor: pointer;
	transition: color 0.2s ease-in-out;
}

.user>i:hover {
	color: var(--textdark-color);
}

.searchUser {
	justify-content: flex-start;
}

.searchUser:hover {
	background: var(--secondary-color);
}

.info {
	display: flex;
	width: calc(100% - 20px);
}

.text {
	width: 250px;
	/*max-width: 100%;*/
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.name {
	color: var(--textdark-color);
	font-weight: 500;
}

.name, .email {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}