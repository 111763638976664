.chips {
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 0px 8px ;
	margin-left: 4px;
	background-color: var(--primaryhover-color);
	border-radius: 8px;
	width: fit-content;
	border: 1px solid transparent;
}
.chips > p {
	color: var(--primary-color-light);
	font-weight: 500;
}
