

.mail {
	display: flex;
	align-items: center;
	color: var(--field-color);
}

.mail i {
	margin: 0px 2px 0px 0px;
	font-size: 12px;
}

.error {
	margin: 8px 0px 0px 0px;
	text-align: center;
	display: flex;
	justify-content:center;
	align-items: center;
}
.error p {
	max-width: 300px;
}