.container {
	display: flex;
	justify-content:center;
	align-items: center;
	object-fit: contain;
}

.container img {
	width: 100%;
	height: 100%;
}