.sidebar {
	/*position: fixed;
	top: 0;
	right: 0;
	bottom: 0;*/
	height: 100%;
	/*max-width: 360px;*/
	width: 360px;
	background: var(--bgsecondary-color);
	display: flex;
	flex-direction: column;
	transform: translateX(100%);
	transition: all 0.3s ease-in-out;
}

.sidebar.active {
	transform: translateX(0);
}

.body {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	flex-grow: 1;
}

.header {
	display: flex;
	align-items: center;
	padding: 20px 16px 0 16px;
	margin: 0px 0px 25px 0px;
}

.header i {
	margin: 0px 9px 0px 0px;
	font-size: 18px;
	color: var(--fieldhover-color);
	cursor: pointer;
}