.modal {
	max-width: 450px;
	width: 90%;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0px 12px 24px rgba(46, 58, 75, 0.42);
	position: relative;
}

.close {
	position: absolute;
	top: -34px;
	right: 8px;
	z-index: 100;
	opacity: 0.7;
	transition: opacity 0.3s ease-in-out;
}

.close:hover {
	opacity: 1;
}

@media (min-width: 991.5px){
	  .close {
		right: 0px;
	  }
}