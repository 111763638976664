.access__container {
	max-width: 700px;
	width: 100%;
	padding: 10px 20px 20px 20px;
	margin: 0px auto 30px auto;
	color: #2E3A4B;
}

.access__container-logo {
	text-align: center;
	margin: 0px auto 9px auto;
	max-width: 200px;
}
.access__container-logo img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.access__container-title {
	margin: 0px 0px 24px 0px;
	text-align: center;
}

.access__container-text,
.access__confirm-text,
.access__help {
	max-width: 565px;
	text-align: center;
	margin: 0px auto 38px auto;
}

.access__confirm-text {
	margin: 24px auto 38px auto;
}

.access__help {
	margin-top: 30px;
}

.access__help span {
	display: inline-block;
	width: 30px;
}

.access__login {
	text-align: center;
	margin: 32px 0px 0px 0px;
}

.access__login a,
.access__help a {
	color: var(--primary-color);
	font-weight: 500;
	text-decoration: none;
}

.a-form {
	max-width: 392px;
	margin: 10px auto 0 auto;
}

.a-form__caption {
	margin: 0px auto 26px auto;
	text-align: center;
	max-width: 324px;
}

.a-form__check {
	margin: 15px 0 25px 0;
	font-size: 14px;
}

.a-form__check a {
	color: var(--primary-color);
	font-weight: 500;
	text-decoration: none;
}

.a-form__btns {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.a-form__btns button {
	margin: 0 12px;
}

.a-form__error {
	margin: 16px auto 16px auto;
	text-align: center;
	max-width: 324px;
}

@media (min-width: 991.5px) {

	.access__container {
		max-width: 670px;
		width: 100%;
		margin: 0px auto 15px auto;
	}

	/*.access__container-title {
		font-size: 40px;
	}*/
}

@media (min-width: 1600.5px) {

	.access__container {
		margin: 0px auto 30px auto;
	}

}