.logo__container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 56px;
	height: 56px;
	border-radius: 50%;
	background-color: #fff;
	overflow: hidden;
}

.logo__img {
	height: 100%;
	display: flex;
	align-items: center;
}

.logo__img>img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.logo__img>p {
	font-weight: 600;
}