.overlay {
	height: 50vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.container {
	display: flex;
	flex-direction: column;
	justify-content:center;
	align-items: center;
	text-align: center;
}

.container .img {
	margin: 0px 0px 16px 0px;
}

.container p > span {
	display: inline-block;
	font-weight: 500;
	color: var(--textdark-color);
	margin: 0px 0px 4px 0px;
}

.container > button {
	margin: 16px 0px 0px 0px;
}

.text__info {
	display: flex;
	align-items: center;
	margin: 8px 0px 0px 0px;
}
.text__info > i {
	font-size: 18px;
	margin: 0px 4px 0px 0px;
}

.text__info > p {
	color: var(--textdark-color);
}