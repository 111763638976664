@font-face {
	font-family: 'icomoon';
	src: url('fonts/icomoon.eot?a9vy4g');
	src: url('fonts/icomoon.eot?a9vy4g#iefix') format('embedded-opentype'),
		url('fonts/icomoon.ttf?a9vy4g') format('truetype'),
		url('fonts/icomoon.woff?a9vy4g') format('woff'),
		url('fonts/icomoon.svg?a9vy4g#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-battery:before {
	content: "\e900";
}

.icon-VR_log-out:before {
	content: "\e901";
}


.icon-User_deactivate:before {
	content: "\e900";
}


.icon-VR_kiosk:before {
	content: "\e909";
}

.icon-VR_kiosk_marker:before {
	content: "\e90a";
	color: rgb(46, 58, 75);
	opacity: 1;
}

.icon-VR_kiosk_marker:after {
	content: "\e93e";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}

.icon-VR_kiosk_on:before {
	content: "\e93f";
}

.icon-sort-outline:before {
	content: "\e901";
	color: rgb(46, 58, 75);
	opacity: 0.5;
}

.icon-sort-outline:after {
	content: "\e902";
	margin-left: -1em;
	color: rgb(46, 58, 75);
}

.icon-attachment:before {
	content: "\e903";
}

.icon-attention:before {
	content: "\e904";
}

.icon-circle-info:before {
	content: "\e905";
}

.icon-database-remove:before {
	content: "\e906";
}

.icon-edit:before {
	content: "\e907";
}

.icon-file-download:before {
	content: "\e908";
}

.icon-uniE909:before {
	content: "\e909";
}

.icon-uniE90A:before {
	content: "\e90a";
}

.icon-log-out-fat:before {
	content: "\e90b";
}

.icon-options-horizontal:before {
	content: "\e90c";
	cursor: pointer;
}

.icon-options-vertical:before {
	content: "\e90d";
	color: var(--field-color);
	font-size: 18px;
	cursor: pointer;
}

.icon-pause:before {
	content: "\e90e";
}

.icon-play_border:before {
	content: "\e90f";
}

.icon-play_filled:before {
	content: "\e910";
}

.icon-skip_next:before {
	content: "\e911";
}

.icon-skip_previous:before {
	content: "\e912";
}

.icon-sort:before {
	content: "\e913";
}

.icon-User_delete:before {
	content: "\e914";
}

.icon-user-add:before {
	content: "\e915";
}

.icon-User-new-team:before {
	content: "\e916";
}

.icon-User-team:before {
	content: "\e917";
}

.icon-videofile:before {
	content: "\e918";
}

.icon-vr:before {
	content: "\e919";
}

.icon-plus:before {
	content: "\e91a";
}

.icon-upload:before {
	content: "\e91b";
}

.icon-account:before {
	content: "\e91c";
}

.icon-add-home:before {
	content: "\e91d";
}

.icon-arrow-down:before {
	content: "\e91e";
}

.icon-arrow-left:before {
	content: "\e91f";
}

.icon-arrow-right:before {
	content: "\e920";
}

.icon-arrow-up:before {
	content: "\e921";
}

.icon-camera:before {
	content: "\e922";
}

.icon-camera_filled:before {
	content: "\e923";
}

.icon-check:before {
	content: "\e924";
}

.icon-chevron-down:before {
	content: "\e925";
}

.icon-chevron-left:before {
	content: "\e926";
	font-size: 16px !important;
}

.icon-chevron-right:before {
	content: "\e927";
}

.icon-chevron-up:before {
	content: "\e928";
}

.icon-close:before {
	content: "\e929";
}

.icon-dashboard:before {
	content: "\e92a";
}


.icon-delete:before {
	content: "\e92b";
}

.icon-event_busy:before {
	content: "\e92c";
}

.icon-eye_off:before {
	content: "\e92d";
}

.icon-eye_on:before {
	content: "\e92e";
}

.icon-filter:before {
	content: "\e92f";
}

.icon-log-out:before {
	content: "\e930";
}

.icon-search:before {
	content: "\e931";
}

.icon-settings:before {
	content: "\e932";
}

.icon-sorting:before {
	content: "\e933";
}

.icon-User:before {
	content: "\e934";
}

.icon-user_circle:before {
	content: "\e935";
}

.icon-user_group:before {
	content: "\e936";
}

.icon-user_manage:before {
	content: "\e937";
}

.icon-video:before {
	content: "\e938";
}

.icon-video_library:before {
	content: "\e939";
}

.icon-email:before {
	content: "\e93a";
}

.icon-user_reactivate:before {
	content: "\e93d";
}

.icon-email_resend:before {
	content: "\e93b";
}

.icon-email_revoke:before {
	content: "\e93c";
}

.icon-battery_bar:before {
	content: "\e942";
}

.icon-battery:before {
	content: "\e940";
}

.icon-VR_log-out:before {
	content: "\e941";
}

.icon-battery_b:before {
	content: "\e943";
}

.icon-reorder:before {
	content: "\e944";
}

.icon-start_session:before {
	content: "\e945";
	/*color: #fff;*/
	color: inherit;
	font-size: 16px !important;
}

.icon-play_circle:before {
	content: "\e946";
	color: #fff;
	color: inherit;
	/*font-size: 16px;*/
}

.icon-pause_circle:before {
	content: "\e947";
	color: #fff;
	font-size: 16px !important;
}

.icon-help_circle:before {
	content: "\e948";
}

.icon-file_download_success:before {
	content: "\e949";
	color: rgb(46, 58, 75);
}

.icon-file_download_success:after {
	content: "\e94a";
	margin-left: -1em;
	color: rgb(37, 189, 93);
}

.icon-warning_circle:before {
	content: "\e94b";
	color: #ffce3c;
}

.icon-stop_session:before {
	content: "\e94c";
	display: flex;
	align-items: center;
	justify-content: center;
	margin: -2px 0px 0px 0px;
	line-height: 1;
	font-size: 20px !important;
}

.icon-offline_pin:before {
	content: "\e94d";
	color: #1fb155;
}

.icon-logo_Pico:before {
	content: "\e94e";
}

.icon-timer:before {
	content: "\e951";
}

.icon-wifi_on:before {
	content: "\e952";
}

.icon-calendar:before {
	content: "\e953";
}

.icon-logo_meta:before {
	content: "\e954";
}

.icon-wifi_off:before {
	content: "\e94f";
}

.icon-clipboard-list:before {
	content: "\e950";
}

.icon-correct_green:before {
	content: "\e955";
	color: #1fb155;
}

.icon-correct:before {
	content: "\e956";
}

.icon-list-box:before {
	content: "\e957";
}

.icon-insights:before {
	content: "\e958";
}

.icon-file_upload_long:before {
	content: "\e959";
}

.icon-cardboard:before {
	content: "\e95a";
}

.icon-circle-warning:before {
	content: "\e95b";
}

.icon-remove-device:before {
	content: "\e95c";
}

.icon-check_circle:before {
	content: "\e960";
	color: #79D099;
}

.icon-delete_circle:before {
	content: "\e95d";
	color: #F18282;

}

.icon-delete_circle:after {
	content: "\e95e";
	margin-left: -1em;
	color: rgb(255, 255, 255);
}

.icon-help_live:before {
	content: "\e95f";
}

.icon-completon_rate:before {
	content: "\e961";
}

.icon-sales:before {
	content: "\e962";
}

.icon-view-insigts:before {
	content: "\e963";
}

.icon-clock:before {
	content: "\e964";
  }