.control {
	display: flex;
	align-items: center;
	/*margin-bottom: 20px;*/
	padding: 20px 0px 20px 16px;
	/*margin-top: -18px;*/
	min-height: 22px;
	background-color: #fff;
	/*box-shadow: 0 8px 6px -6px var(--textdark-color);*/
}
.control label {
	
}

.checked__num {
	width: 80px;
	padding-left: 10px;
}

.buttons {
	display: flex;
	align-items: center;
	margin: 0px 0px 0px 10px;
}

.buttons > i {
	margin: 0px 25px 0px 0px;
	font-size: 22px;
	color: var(--textdark-color);
	cursor: pointer;
}

.buttons > i:hover {
	color: var(--primary-color);
}

.icon {
	display: flex;
	flex-direction: column;
	justify-content:center;
	align-items: center;
	font-size: 24px;
	color: var(--textdark-color);
	padding: 0 8px;
}
.icon i {
	margin: 0px 0px 7px 0px;
}

@media (max-width: 991.5px){
	.buttons {
		margin: 0px 0px 0px 0px;
	}
	.control {
		padding: 20px 0px 20px 19px;
		justify-content: center;
		margin-bottom: 0px;
	}
}