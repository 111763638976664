.inputs {
	display: flex;
	padding-bottom: 2px;
}

.searchInput {
	margin: 0px 24px 0px 0px;
	max-width: 379px;
	width: 100%;
}

.select {
	max-width: 285px;
	width: 100%;
}