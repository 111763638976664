.big,
.mid,
.header,
.subheader {
	color: var(--textdark-color);
	cursor: default;
	cursor: inherit;
}

.big,
.mid,
.header {
	font-weight: 700;
}

.big {
	font-size: 40px;
}

.mid {
	font-size: 30px;
}

.header {
	font-size: 25px;
}

.subheader {
	font-weight: 600;
	font-size: 20px;
}

.paragraph {
	font-weight: 400;
	font-size: 16px;
	color: var(--fieldhover-color);
	cursor: default;
	cursor: inherit;
}

.caption {
	font-weight: 400;
	font-size: 12px;
	color: var(--field-color)
}

.texterror {
	font-weight: 400;
	font-size: 16px;
	color: var(--error-color);
}

@media (min-width: 1660px) {
	.mid {
		font-size: 30px;
	}
}