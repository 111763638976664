.content {

	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin: 0px 0px 24px 0px;
}

.content > p {
	color: var(--textdark-color);
	margin: 0px 0px 16px 0px;
}

.content > p:nth-child(2) {
	margin: 0px 0px 16px 0px;
}

.content > p > a {
	/*color: var(--primary-color);
	font-weight: 600;*/
	text-decoration: none;
	color: var(--textdark-color);
}

.content > p > a:hover {
	color: var(--primary-hover-color);
}

.links {
	display: flex;
	justify-content:center;
	align-items: center;
}

.links a {
	margin: 0 8px;
}