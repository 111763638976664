.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0px 0px 23px 0px;
	width: 100%;
	overflow: hidden;
}

.header__group {
    display: flex;
    flex-direction: column; /* Розташовує елементи вертикально */
    align-items: flex-start; /* Вирівнює по лівому краю */
    gap: 14px; /* Відступ між кнопкою назад і заголовком */
}

.header__teamName {
    margin-left: 2px; /* Легкий відступ, щоб вирівняти */
	font-size: 30px;
	font-weight: 600;
}

@media (max-width: 991.5px){
	.header__teamName {
		margin-left: 0px; 
		margin-bottom: 8px;
		font-size: 25px;
	}
}

.invite {
	display:flex;
	width: 100%;
}

.invite>button {
	margin-left: 16px;
}

.links {
	margin: 18px auto 5px auto;
	display: flex;
	justify-content: center;
	max-width: 270px;
}
.link {
	position: relative;
	display: flex;
	justify-content:center;
	align-items: center;
	margin: 0 8px;
	color: var(--fieldhover-color);
	cursor: pointer;
}
.link.active {
	color: var(--primary-color);
	font-weight: 600;
}

.link.active::after{
	content: '';
	position: absolute;
	bottom: -5px;
	left: 50%;
	transform: translateX(-50%);
	width: 32px;
	height: 3px;
	border-radius: 2px;
	background-color: var(--primary-color);
}

.actions {
	display: flex;
	align-items: center;
	gap: 28px;
}

.actions i {
	font-size: 24px;
	
}

.actions i:not(:first-child) {
color: var(--textdark-color);
}


.invite__btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	font-size: 14px !important;
	border-radius: 50%;
	background-color: var(--primary-color);
	color: var(--textlight-color);
}

/*.header__title h3, .header__title h2 {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}*/

.tabs {
	margin-top: 18px;
}

/*@media (max-width: 499px){
	.header__title h3, .header__title h2 {
		max-width: 150px;
	}
}
@media (min-width: 500px){
	.header__title h3, .header__title h2 {
		max-width: 300px;
	}
}*/