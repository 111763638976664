.form {
	width: 100%;
	max-width: 392px;
}

.input {
	margin-top: 20px;
}


.form p {
	text-align: center;
	margin: 21px 0px 0px 0px;
}

.form>p,
.support p {
	color: var(--textdark-color)
}

.support p {
	margin: 32px 0px 0px 0px;
}
.support a {
	color: var(--primary-color);
	text-decoration: none;
}

.button {
	margin: 22px 0px 0px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

	.button > button {
		margin: 0 8px;
	}

.input.code {
	display: flex;
	justify-content: center;
}