.wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	/*background: var(--textdark-color);*/
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	padding: 14px 32px;
	height: fit-content;


	background: rgba(46, 58, 75, 0.95);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 900;
}

.block {
	display: flex;
	align-items: center;
	color: #fff;
	font-weight: 400;
	margin: 0px 8px 0px 0px;
}

.block p {
	color: inherit;
	font-weight: inherit;
}

.block p>span {
	font-weight: 600;
}

.block>img {
	margin: 0px 8px 0px 0px;
}

.buttons {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.buttons>button {
	margin: 0px 0px 0px 16px;
}

@media (max-width: 991.5px) {
	.wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 101;
	}
}

@media (max-width: 767.5px) {
	.wrap {
		flex-direction: column;
		padding: 16px;
	}

	.buttons {
		align-self: flex-end;
		justify-content: end;
		margin-top: 8px;
	}

	.block {
		align-items: flex-start;
	}
}