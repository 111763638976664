.container {
	position: relative;
	height: 350px;
	width: 100%;
}

.searchUsers {
	position: absolute;
	width: 100%;
	background-color: #fff;
	margin: 6px 0px 0px 0px;
	padding: 10px 0;
	box-shadow: 2px 4px 10px rgba(46, 58, 75, 0.16), 0px 0px 4px rgba(46, 58, 75, 0.12);
	border-radius: 6px;
	z-index: 10;
}

.scroll {
	height: 280px;
	overflow-y: scroll;
	overflow-x: hidden;
	
}

/*.scroll::-webkit-scrollbar {
	width: 4px;
	cursor: pointer;
}

.scroll::-webkit-scrollbar-track {
	background: transparent;
	cursor: pointer;
}

.scroll::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--fielddisabled-color);
	cursor: pointer;
}*/

/* WebKit (Safari, Chrome) */
.scroll::-webkit-scrollbar {
	width: 4px;
	cursor: pointer;
}

.scroll::-webkit-scrollbar-track {
	background: transparent;
	cursor: pointer;
}

.scroll::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--fielddisabled-color);
	cursor: pointer;
}

/* Firefox */
.scroll {
	scrollbar-width: thin; /* Adjust the value as needed */
	scrollbar-color: var(--fielddisabled-color) transparent;
}

/* Internet Explorer 11 */
.scroll {
	scrollbar-face-color: var(--fielddisabled-color);
	scrollbar-track-color: transparent;
}

/* Microsoft Edge */
.scroll {
	scrollbar-color: var(--fielddisabled-color) transparent;
}

.noUsers {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: 100%;
}

.userCard {
	margin: 24px 0px 0px 0px;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.userCard .name {
	color: var(--textdark-color);
	margin: 8px 0px 0px 0px;
}

.avatar {
	height: 108px;
	width: 108px;
	flex-basis: 108px;
	flex-shrink: 0;
}

@media (max-width: 550px){
	.avatar {
		height: 80px;
		width: 80px;
		flex-basis: 80px;
	}
}
