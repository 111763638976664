.row {
	display: flex;
	align-items: center;
}

.box {
	display: inline-flex;
	margin: 0px 8px 0px 0px;
}

.checkbox[type="checkbox"] {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	margin: 0;
	width: 18px;
	height: 18px;
	border: 2px solid var(--field-color);
	border-radius: 2px;
	transition: opacity 0.1s ease-in-out;
	cursor: pointer;
}

.checkbox[type="checkbox"].error {
	border-color: var(--error-color);
}

.checkbox[type="checkbox"]::before {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	content: "\e924";
	font-family: 'icomoon';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 18px;
	height: 18px;
	font-size: 16px;
	font-weight: 600;
	color: var(--textlight-color);
	background-color: var(--primary-color);
	border-radius: 2px;
	opacity: 0;
	transition: opacity 0.1s ease-in-out;
}

.minus[type="checkbox"] {
	border: 2px solid var(--field-color);
}

.minus[type="checkbox"]::before {
	/*content: "\268A";*/
	content: url(../../assets/icons/minus.svg);
	font-size: 0;
	/*font-size: 10px;*/
}

.checkbox:checked::before {
	opacity: 1;
}

.checkbox[type="checkbox"]:checked {
	border: 1px transparent;
}

.checkbox::after {
	content: '';
	width: 38px;
	height: 38px;
	position: absolute;
	top: -12px;
	left: -12px;
	border-radius: 50%;
	background-color: var(--secondary-color);
	z-index: -1;
	opacity: 0;
	transition: opacity 0.1s ease-in-out;
}

.minus::after {
	top: -12px;
	left: -12px;
}

.minus:checked::after {
	top: -10px;
	left: -10px;
}

.checkbox:hover::after,
.checkbox:hover {
	background-color: var(--secondary-color);
	opacity: 1;
	transition: opacity 0.1s ease-in-out;
}

.checkbox:active::after,
.checkbox:active {
	background-color: var(--primary-color-shadow);
	transition: opacity 0.1s ease-in-out;
}

.checkbox[type="checkbox"]:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.label {
	color: var(--fieldhover-color);
	cursor: pointer;
}
.label.checked {
	color: var(--textdark-color);
}
.label.disabled {
	cursor: not-allowed;
	opacity: 0.5;
}


.checkbox[type="checkbox"]:disabled:hover::after {
	opacity: 0;
}

.checkbox[type="checkbox"]:disabled:hover {
	background-color: transparent;
}