.card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 16px 0px 0px 0px;
}

.card>img {
	margin: 0px 0px 20px 0px;
}

.card>p {
	text-align: center;
	white-space: nowrap;
}

.card>p>span {
	display: inline-block;
	color: var(--textdark-color);
	font-weight: 500;
	margin: 0px 0px 4px 0px;
}

@media (max-width: 767.5px) {

	.card>img {
		width: 120px;
		margin-top: 16px;
	}
}

@media (max-width: 540.5px) {

	/*.card {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: 0;
	}*/

	.card>img {
		margin: 0px 0px 16px 0px;
		width: 120px;
	}
}