.container {
	position: fixed;
	bottom: 64px;
	right: 64px;
	background: #FFFFFF;
	padding: 24px 24px 0 24px;
	z-index: 100;
	box-shadow: 2px 4px 10px rgba(46, 58, 75, 0.16), 0px 0px 4px rgba(46, 58, 75, 0.12);
	border-radius: 6px;
	max-width: 376px;
	width: 100%;
}

.video {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 0px 0px 33px 0px;
}

.info {
	overflow: hidden;
	flex-grow: 1;
}

.info>p {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

}

.info>p>span {
	font-weight: 500;
	color: var(--textdark-color);
}

.progressbar {
	display: flex;
	align-items: baseline;
	margin: 8px 0px 0px 0px;
}

.progressbar > p {
	white-space: nowrap;
	display: inline-block;
	width: 38px;
	text-align: right;
}

.bar {
	height: 3px;
	width: 90%;
	background: rgba(46, 58, 75, 0.3);
	border-radius: 2px;
	margin: 0px 8px 0px 0px;
}

.progress {
	height: 3px;
	width: 38%;
	background: var(--primary-color);
	border-radius: 2px;
}

.action {
	flex-basis: 24px;
	overflow: hidden;
	margin: 0px 0px 0px 24px;
	flex-shrink: 0;
}

.action>i {
	font-size: 22px;
	color: var(--fieldhover-color);
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.action>i:hover {
	color: var(--textdark-color);
}

.queue {
	display: flex;
	justify-content: space-between;
	margin: 0px 0px 24px 0px;
}