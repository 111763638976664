.sales, .success__sent {
	margin: 16px auto;
	text-align: center;
	width: fit-content;
}

.sales h3,.success__sent h3 {
	margin: 0px 0px 10px 0px;
}
.sales p {
	margin: 0px 0px 20px 0px;
}

.success__sent p {
	margin: 0px 0px 13px 0px;
}
 
.form {
	max-width: 392px;
	width: 100%;
	text-align: left;
}
.buttons {
	margin-top: 20px;
	text-align: center;
}

.buttons button {
	margin: 0 8px;
}

@media (max-width: 490.5px) {
	.success__sent img {
		width: 150px;
	}
	.success__sent p br {
		display: none;
	}
}