.form {
	width: 100%;
	max-width: 392px;
	padding-top: 8px;
}

.input {
	margin-top: 5px;
}

.form > .input:nth-child(2) {
	margin-top: 16px;
}

.policy {
	display: flex;
	align-items: center;
	margin: 20px 0px 0px 0px;
}


.policy p a {
	color: var(--primary-color);
	text-decoration: none;
}

.button {
	margin: 22px 0px 0px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button button {
	margin: 0px 8px;
}

.conditions {
	margin: 3px 0px 8px 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.condition {
	display: flex;
	align-items: center;
	margin: 0px 0px 2px 0px;
	/*flex-basis: 50%;
	flex-grow: 0;
	flex-shrink: 0;*/
}

.condition>i {
	margin: 0px 2px 0px 0px;
	color: var(--field-color);
}

.condition.checked p {
	color: var(--success-color);
}