.mobMenu {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content:center;
	align-items: center;
	background-color: #fff;
	z-index: 110;
}
.tabs {
	max-width: 360px;
	width: 100%;
	display: flex;
}
.item {
	display: flex;
	flex-direction: column;
	justify-content:center;
	align-items: center;
	width: 25%;
	height: 60px;
	padding: 10px 10px 16px 10px;
	text-decoration: none;
}

.item.big {
	width: 33.3%;
}

.item > i {
	margin: 0px 0px 0px 0px;
}

.item > i:before {
	font-size: 25px;
	color: var(--field-color);
}

.item.active>p,
.item:hover>p,
.item.active>i:before,
.item:hover>i:before {
	color: var(--primary-color);
}