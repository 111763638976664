.page {
	padding: 16px;
}

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.logo {
	margin: 14px 0px 0px 0px;
	width: 120px;
}

.success__activate {
	text-align: center;
}

.success__activate > img {
	margin-bottom: 16px;
}

.success__activate > p:nth-child(2) {
	margin: 0px 0px 12px 0px;
	font-weight: 500;
	color: var(--textdark-color);
}
.success__activate > button {
	margin: 16px auto 0px auto;
}

.powered {
	cursor: pointer;
}

.powered > p {
	margin: 4px auto 0 auto;
	text-align: center;
	color: var(--fieldhover-color);
}

@media (min-width: 768px) {
	.page {
		padding: 32px;
	}

	.container {
		margin: 0px 0px 0px 0px;
	}

	.logo {
		width: 160px;
	}
}

@media (min-width: 1900px) {
	.container {
		margin: 112px 0px 0px 0px;
	}

}

