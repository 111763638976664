.container {
	width: 100%;
}

.container > p {
	margin: 0px 0px 15px 0px;
	color: VAR(--textDark-color)
}

.checkbox {
	margin: 0px 0px 30px 0px;
}

.checkbox label {
	margin: 0px 0px 0px 19px;
}

.scroll {
	height: 280px;
	overflow-y: auto;
}

/*.scroll::-webkit-scrollbar {
	width: 4px;
	cursor: pointer;
}

.scroll::-webkit-scrollbar-track {
	background: transparent;
	cursor: pointer;
}

.scroll::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--fielddisabled-color);
	cursor: pointer;
}*/

/* WebKit (Safari, Chrome) */
.scroll::-webkit-scrollbar {
	width: 4px;
	cursor: pointer;
}

.scroll::-webkit-scrollbar-button {
	display: none; /* Видаляє маленькі стрілки */
}

.scroll::-webkit-scrollbar-track {
	background: transparent;
	cursor: pointer;
}

.scroll::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--fielddisabled-color);
	cursor: pointer;
}

/* Firefox */
.scroll {
	scrollbar-width: thin; /* Adjust the value as needed */
	scrollbar-color: var(--fielddisabled-color) transparent;
}

/* Internet Explorer 11 */
.scroll {
	scrollbar-face-color: var(--fielddisabled-color);
	scrollbar-track-color: transparent;
}

/* Microsoft Edge */
.scroll {
	scrollbar-color: var(--fielddisabled-color) transparent;
}

.emptyState {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
  }
  
  .emptyImage {
	width: 208px;
	margin-bottom: 20px;
  }

  @media (max-width: 770px) {
	.emptyImage {
		width: 120px;
	  }
  }