.form {
	width: 100%;
	max-width: 392px;
}

.input {
	margin-top: 12px;
}

.forgot {
	text-align: right;
	margin: 4px 0px 0px 0px;
}

.forgot a {
	color: var(--primary-color);
	text-decoration: none;
	font-size: 12px;
}

.button, .buttons {
	margin: 22px 0px 0px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.button > p {
	margin: 12px 0;
	flex-basis: 100%;
	text-align: center;
}

.button > button {
	margin: 0 12px;
}

.buttons {
	flex-direction: row;
}

.buttons > button {
	margin: 0 8px;
}


.signin, .signup {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 21px 0px 0px 0px;
}

.signin p {
	font-weight: 500;
	color: var(--field-color);
}

.signup p {
	color: var(--textdark-color);
}


.signup button {
	margin: 0px 0px 0px 16px;
}

.signin button {
	margin: 0px 0px 0px 6px;
}

.resend__code {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
}

.counter {
	display: inline-block;
	width: 60px;
}

.subheader {
	margin: 16px 0;
	color: var(--textdark-color);
}

.support {
	color: var(--textdark-color);
	margin: 24px 0;
	max-width: 350px;
	text-align: center;
}

@media screen and (max-width: 767.5px) and (min-height: 750px) {
	.signup {
		position: fixed;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
	}
}