.google__btn {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 36px;
	padding: 0 8px;
	border-radius: 6px;
	background-color: #fff;
	border: 1px solid var(--field-color);
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	margin-bottom: 16px;
	width: 240px;
}

.google__btn:hover,
.google__btn:focus {
	/*border: 1px solid var(--fieldhover-color);*/
	background: var(--secondary-color);
}

/*.google__btn:hover span {
	color: var(--textdark-color);
}*/

.img {
	width: 20px;
	height: 20px;
	margin-right: 8px;
}

.img>img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.google__btn>span {
	display: flex;
	align-items: center;
	height: 100%;
	font-size: 16px;
	font-weight: 400;
	color: var(--fieldhover-color);
	border-left: 1px solid var(--field-color);
	padding-left: 8px;
	transition: all 0.3s ease-in-out;
}