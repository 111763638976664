.tooltip {
	max-width: 390px !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	filter: drop-shadow(2px 4px 10px rgba(46, 58, 75, 0.16)) drop-shadow(0px 0px 4px rgba(46, 58, 75, 0.12)) !important;
	opacity: 1 !important;
	white-space: normal;
}

.tooltip__black {
	font-size: 12px !important;
	opacity: 1 !important;
	padding: 4px;
}