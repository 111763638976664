.container {
	max-width: 440px;
	width: 100%;
	padding: 40px 24px 40px 24px;
}

.container h3 {
	text-align: center;
	margin: 0px 0px 25px 0px;
	max-width: 440px;
	font-weight: 700;
}

.container p {
	color: var(--textdark-color);
}

.role {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0px 0px 20px 0px;
}

.buttons {
	width: 100%;
	display: flex;
	justify-content: center;
}

.buttons button:nth-child(1) {
	margin: 0px 24px 0px 0px;
}

.emails {
	width: 100%;
	margin: 0px 0px 24px 0px;
}

.eContainer {
	height: 96px;
	width: 100%;
	padding: 12px;
	border: 1px solid var(--field-color);
	border-radius: 6px;
	overflow-y: scroll;
	overflow-x: hidden;
}

/* WebKit (Safari, Chrome) */
.eContainer::-webkit-scrollbar {
	width: 4px;
	cursor: pointer;
}

.eContainer::-webkit-scrollbar-track {
	background: transparent;
	margin: 5px 0;
	cursor: pointer;
}

.eContainer::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--fielddisabled-color);
	cursor: pointer;
}

/* Firefox */
.eContainer {
	scrollbar-width: thin; /* Adjust the value as needed */
	scrollbar-color: var(--fielddisabled-color) transparent;
}

/* Internet Explorer 11 */
.eContainer {
	scrollbar-face-color: var(--fielddisabled-color);
	scrollbar-track-color: transparent;
}

/* Microsoft Edge */
.eContainer {
	scrollbar-color: var(--fielddisabled-color) transparent;
}


/*.eContainer::-webkit-scrollbar {
	width: 4px;
	cursor: pointer;
}

.eContainer::-webkit-scrollbar-track {
	background: transparent;
	margin: 5px 0;
	cursor: pointer;
}

.eContainer::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--fielddisabled-color);
	cursor: pointer;
}*/

.email {
	display: flex;
	width: fit-content;
	align-items: center;
	padding: 4px 12px;
	background-color: var(--secondary-color);
	border-radius: 12px;
}

.email:not(:last-child) {
	margin: 0px 0px 8px 0px;
}

.email p {
	margin: 0px 0px 0px 0px;
	cursor: default;
}

.email i {
	width: 12px;
	height: 12px;
	font-size: 15px;
	font-weight: 900;
	margin: 0px 0px 0px 5px;
	color: var(--fieldhover-color);
	cursor: pointer;
}

.email i:hover {
	color: var(--textdark-color);
}

.input input {
	width: 100%;
	font-size: 16px;
	color: var(--textdark-color);
	padding: 5px 0;
	border: none;
}

.input input:focus {
	outline: none;
}