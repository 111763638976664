.overlay {
	background: rgba(46, 58, 75, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content:center;
	align-items: center;
	transition: all 0.3s ease-in-out;
	opacity: 0;
	visibility: hidden;
	z-index: 1000;
	cursor: pointer;
}

.overlay.active {
	opacity: 1;
	visibility: visible;
}