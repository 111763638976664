:root {
	--surface-c: #2E3A4B
}

/*tabs*/

.p-tabview .p-tabview-nav {
	width: fit-content;
	margin: 0 auto;
	border-color: transparent;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	border-color: transparent;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	padding: 0.5rem 0.5rem;
	color: var(--fieldhover-color);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	color: var(--primary-color);
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
	background: var(--primary-color);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
	/*background-color: #d5e6f969;*/
	background-color: transparent;
}


/*sidebar*/

.p-panelmenu .p-panelmenu-panel {
	box-shadow: none;
}

.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content,
.p-panelmenu .p-panelmenu-content {
	background: transparent !important;
}


.panel .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled)>.p-menuitem-content:hover {
	background-color: transparent !important;
	box-shadow: none !important;
	color: red !important;
}

data-p-focused {
	background-color: navy !important;
}

/*table*/



.p-datatable .p-datatable-thead>tr>th {
	background-color: var(--secondary-color) !important;
	transition: ease-in-out 0.2s;
}

.p-datatable .p-datatable-thead>tr>.p-sortable-column:hover,
.p-datatable .p-datatable-thead>tr>.p-sortable-column:hover .p-sortable-column-icon {
	/*background-color: var(--primaryhover-color) !important;*/
	color: var(--primary-color) !important;
}

.p-datatable .p-datatable-thead>tr>.p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
	color: var(--primary-color) !important;
}

.p-datatable th:first-child,
.p-datatable td:first-child {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.p-datatable th:last-child,
.p-datatable td:last-child {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.p-datatable th,
.p-datatable td {
	padding: 8px 8px;
}

.p-datatable-thead .p-selection-column {
	padding: 12px !important;
}

.p-datatable th:first-child {
	padding: 12px 16px !important;
}

.p-datatable-tbody .p-selection-column {
	padding: 16px !important;
}


[data-pc-section="sort"] {
	margin-left: 8px;
}

.p-datatable .p-datatable-tbody>tr,
.p-datatable th,
.p-datatable .p-sortable-column .p-sortable-column-icon {
	color: var(--textdark-color) !important;
	transition: ease-in-out 100ms;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:not(.p-datatable-emptymessage):hover {
	background-color: var(--primaryhover-color) !important;
	/*background-color: transparent !important;*/
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
	background-color: var(--secondary-color) !important;
	/*background-color: transparent !important;*/

}

.p-datatable .p-datatable-tbody>tr>td {
	text-align: left;
	border-color: var(--secondary-color);
	border-top-color: white;
	border-width: 4px 0 1px 0;
}

.pr-table-header-checkbox>div {
	position: absolute;
	top: -40px;
	/*z-index: 1000;*/
	/*z-index: 1;*/
	display: none;
}


.p-datatable-wrapper::-webkit-scrollbar {
	width: 6px;
	cursor: pointer;
}

.p-datatable-wrapper::-webkit-scrollbar-track {
	background: var(--secondary-color);
	cursor: pointer;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb {
	background: var(--field-color);
	cursor: pointer;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb:hover {
	background: var(--field-color);
}

/* Стили для Firefox */
.p-datatable-wrapper {
	scrollbar-width: thin;
	scrollbar-color: var(--fielddisabled-color) var(--secondary-color);
	/* Цвет ползунка и фона дорожки */
	scrollbar-arrow-color: var(--secondary-color);
}


.p-datatable>tbody>tr {
	border: 4px solid red;
	/* Добавляем отступ между строками */
}

/*checkbox*/

.p-checkbox-input,
.p-checkbox .p-checkbox-box {
	border: 2px solid var(--field-color) !important;
}

.p-checkbox.p-highlight .p-checkbox-box {
	background: var(--primary-color) !important;
	border: 2px solid var(--primary-color) !important;
}

.p-checkbox-indeterminate .p-checkbox-box {
	background: var(--primary-color) !important;
	border: 2px solid var(--primary-color) !important;
	position: relative;
}

.p-checkbox-indeterminate .p-checkbox-box:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: 2px;
	background-color: #fff;
	/* Черный цвет полоски */
	transform: translate(-50%, -50%);
}

.p-checkbox-box .p-checkbox-icon {
	display: none;
	/* Скрываем стандартную галочку */
}

.p-checkbox.p-highlight .p-checkbox-box:after {
	content: '\e924';
	font-family: 'icomoon';
	color: #fff;
	font-size: 18px;
	position: absolute;
	top: 50%;
	left: 50%;
	background-size: cover;
	background-repeat: no-repeat;
	transform: translate(-50%, -50%);
}

/*tooltip*/

.p-tooltip>div {
	padding: 6px 12px;
	background-color: var(--textdark-color);
	max-width: 390px !important;
	border-radius: 6px !important;
}

.p-tooltip-arrow {
	background-color: red;
}

/*overlay*/

.p-component-overlay {
	background: rgba(46, 58, 75, 0.5);
}

/*scrollbar*/

/* ScrollPanelDemo.css */

.p-scrollpanel.main__scrollbar .p-scrollpanel-bar {
	background-color: var(--fielddisabled-color);
	width: 6px;
	transition: background opacity 0.3s ease-in-out;
}

.p-scrollpanel.main__scrollbar .p-scrollpanel-bar:hover,
.p-scrollpanel.main__scrollbar .p-scrollpanel-bar:focus,
.p-scrollpanel.main__scrollbar .p-scrollpanel-bar:active{
	background-color: var(--fieldhover-color);
}

.p-scrollpanel.main__scrollbar {
	width: 4px;
}

.main__scrollbar .p-scrollpanel-content {
	height: 100%;
}

@media  (hover: none) {
	.main__scrollbar .p-scrollpanel-content {
		max-width: 100%;
	}
	
}
