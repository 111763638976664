.container {
	padding: 40px 24px 40px 24px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 500px; /* Максимальна ширина модального вікна */
    width: 90%; /* Ширина модального вікна у відсотках */
    margin: 0 auto; /* Центрування вікна */
}

.container h3 {
	text-align: center;
	margin: 0px 0px 25px 0px;
	max-width: 328px;
}

.buttons {
	width: 100%;
	display: flex;
	justify-content: center;
}

.buttons button:nth-child(2) {
	margin: 0px 0px 0px 24px;
}

.bodyText {
	margin-bottom: 25px; /* Нижній відступ */
	white-space: nowrap; /* Запобігання переносу тексту */

  }

@media (max-width: 768px) {
	.container h3 {
		font-weight: 600;
	}

}

@media (max-width: 540px) {

	
	.bodyText {
        white-space: normal; /* Дозволяємо перенос тексту */
        text-align: center; /* Центруємо текст */
    }

	.container{
		max-width: 350px;
		padding: 24px 24px 24px 24px;
	}

	.bodyText{
		font-size: 18px;
	}
}
