.container {
	display: flex;
	justify-content:center;
	align-items: center;
	background-color: var(--textdark-color);
	height: 100vh;
}

.payment {
	width: 400px;
	padding: 20px;
	background-color: #fff;
	margin: 0 auto;
}

.devider {
	width: 100%;
	height: 2px;
	background-color: var(--field-color);
	margin: 20px 0;
}

.payment__info__item > p:nth-child(1) {
	font-weight: 500;
	color: var(--field-color);
}

.payment__info__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.payment__info__item > p {
	flex-basis: 50%;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0px 0px 8px 0px;
}
