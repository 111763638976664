.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0px 0px 23px 0px;
}

.create {
	display: flex;
}

.create>button {
	margin-left: 16px;
}

.searchInput {
	/*margin: 0px 0px 19px 0px;*/
}

@media (min-width: 768px) {
	.searchInput {
		max-width: 379px;
	}
}

.create__btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	font-size: 14px !important;
	border-radius: 50%;
	background-color: var(--primary-color);
	color: var(--textlight-color);
}

.actions {
	display: flex;
	align-items: center;
	gap: 28px;
}

.actions i {
	font-size: 24px;
}
.actions i:not(:first-child) {
color: var(--textdark-color);
}