.container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.container img {
	width: 100%;
	height: 100%;
}