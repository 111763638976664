.container {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transition: all 0.2s ease-in-out;
	user-select: none;
}

.title {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px 54px 10px 32px;
	cursor: pointer;
	text-decoration: none;
	z-index: 5;
	background: var(--secondary-color);
}

.title i {
	margin: 0px 9px 0px 0px;
	z-index: 100;
	font-size: 24px;
}

.title i::before {
	/*color: var(--field-color);*/
	/*color: var(--textdark-color);*/
	color: var(--fieldhover-color);
}

.title > p {
	color: var(--textdark-color);
	font-weight: 400;
}

.title.active {
	background: var(--secondary-color);
}

.title.active>p,
.title.active>i:before,
.title.active+.chevron {
	color: var(--primary-color) !important;

}

.title.active>p,
.title.active+.chevron {
	font-weight: 600;
}



.chevron {
	position: absolute;
	color: var(--fieldhover-color);
	top: 14px;
	right: 30px;
	font-size: 20px;
	font-weight: 700 !important;
	z-index: 6;
	cursor: pointer;
}

.block {
	opacity: 1;
	transition: opacity 0.2s ease-in-out;
}

.title:hover,
.chevron:hover+.title {
	background-color: var(--primaryhover-color);
}

@media (max-width: 1200px) {
	.title {
		padding: 10px 32px 10px 16px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.title>p {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.chevron {
		right: 12px;
	}
}

@media (max-width: 920px) {
	.title {
		padding: 10px 36px 10px 16px;
	}

	.chevron {
		right: 33px;
	}
}