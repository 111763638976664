.input {
	width: 100%;
	padding: 8px 16px 10px 16px;
	border: 1px solid var(--field-color);
	border-radius: 6px;
	font-weight: 400;
	font-size: 16px;
	color: var(--textdark-color);
	letter-spacing: 0.035em;
}

.input::placeholder {
	color: var(--field-color);
}

.input:hover {
	/*padding: 7.5px 15.5px 9.5px 15.5px;*/
	border-color: var(--fieldhover-color);
	outline: 1.5px solid var(--fieldhover-color);
	/*border: 1.5px solid var(--fieldhover-color);*/
}

.input:focus {
	/*padding: 7.5px 15.5px 9.5px 15.5px;*/
	border-color: transparent;
	outline: 2px solid var(--primary-color) !important;
	caret-color: var(--primary-color);
	/*border: 1.5px solid var(--primary-color);*/
	/*outline: none;*/
}

.input:focus::marker {
	color: var(--primary-color);
	width: 5px;
}

.input:disabled {
	border-color: var(--fielddisabled-color);
	background-color: var(--bgsecondary-color);
	color: var(--fielddisabled-color);
	cursor: not-allowed;
}

.input:disabled::placeholder {
	color: var(--fielddisabled-color);
}

.input:disabled:hover {
	border-color: var(--fielddisabled-color);
	outline: none;
}

.input:disabled:focus {
	border-color: var(--fielddisabled-color);
	outline: none;
}


.label {
	display: flex;
	margin: 0px 0px 3px 0px;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.035em;
	color: var(--textdark-color);
}

.label.disabled {
	color: var(--fielddisabled-color);
}

.helper {
	display: block;
	margin: 4px 0px 0px 0px;
	min-height: 15px;
	font-weight: 400;
	font-size: 12px;
	letter-spacing: 0.035em;
	color: var(--field-color);
}

.input.error {
	border-color: var(--error-color);
}

.input.error:focus {
	outline: 1px solid var(--error-color) !important;
}

.helper.error {
	color: var(--error-color);
}

/*.input[type="password"] {
	-webkit-text-security: square;
}*/
.inputWrap {
	position: relative;
	display: inline-block;
	width: 100%;
}

.cardInput .input {
	padding: 8px 16px 10px 48px;
}

.cardInput > i {
	position: absolute;
	font-size: 25px;
	top: 50%;
	left: 16px;
	transform: translateY(-50%);
	color: var(--field-color);
	cursor: pointer;
}

.input.password {
	padding-right: 40px;
}

.icon {
	position: absolute;
	top: 50%;
	right: 16px;
	transform: translateY(-50%);
	color: var(--field-color);
	cursor: pointer;
}

.tooltip__info {
	display: inline-flex;
	margin: 0px 0px 0px 4px;
}

.tooltip__info i {
	font-size: 20px;
	color: var(--field-color);
	cursor: pointer;
}

.tooltip__info i:hover {
	color: var(--primary-color);
}