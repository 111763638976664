.search__row {
	display: flex;
	align-items: center;
	width: 100%;
	margin: -2px;
	padding: 2px;
}

.search__row > i {
	font-size: 30px;
	color: var(--fieldhover-color);
	margin-right: 8px;
}