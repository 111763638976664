.search {
	max-width: 379px;
	width: 100%;
	margin: 0 0 20px 0;
}


.button_wrapper{
	padding-top: 24px; 
}

  
