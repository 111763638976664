.err {
	margin-top: 49px;
}

.btn {
	margin-top: 52px;
}

.login {
	margin-top: 32px;
	color: var(--textdark-color);
}

.login a {
	color: var(--primary-color);
	font-weight: 600;
	text-decoration: none;
}