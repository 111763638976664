.search>input {
	padding-left: 46px;
	padding-right: 40px;
}

.search {
	position: relative;
	/*margin: 0px 0px 24px 0px;*/
}

.search::before {
	content: '\e931';
	position: absolute;
	left: 18px;
	top: 50%;
	transform: translateY(-50%);
	width: 20px;
	height: 20px;
	font-size: 20px;
	color: var(--field-color);
}

.wrap {
	position: relative;
	width: 100%;
}

.wrap>i {
	position: absolute;
	right: 16px;
	top: 10px;
	display: flex;
	justify-content:center;
	align-items: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: var(--secondary-color);
	font-size: 14px;
	font-weight: 900;
	color: var(--field-color);
	cursor: pointer;
}
.wrap span {
	display: none;
}

.wrap > i:hover {
	color: var(--textdark-color);
}