.chips {
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 1px 8px 2px 8px;
	background-color: #FFDADA;
	border-radius: 8px;
	width: fit-content;
}
.chips > p {
	color: #E15A45;
	font-weight: 500;
	text-transform: lowercase;
}
.chips > p::first-letter {
	text-transform: uppercase;
}