@keyframes fadeIn {
  from {
    opacity: 0;
	 /*transform: translateX(-100%);*/
	 /*transform: translateY(-100%);*/
  }
  to {
    opacity: 1;
	 /*transform: translate(0);*/
  }
}

.tooltipe {
	padding: 12px 16px;
	width: 330px;
	background-color: var(--textdark-color);
	border-radius: 6px;
	animation: fadeIn 0.5s ease-in-out forwards;
}

.tooltipe p {
	color: #FFFFFFB2;
}

.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
}

.footer__steps {
	color: #FFFFFFB2;
}

.footer__steps span {
	color: #fff;
	font-weight: 600;
}

.buttons button {
	margin-left: 16px;
}