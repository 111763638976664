/*@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
@import './pages/Waitlist.css';


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Inter', sans-serif;
	scroll-behavior: smooth;
}


body {
	/*overflow: hidden;*/
	
}

/*variables*/

:root {
	--primary-color: #005DFF;
	--primary-hover-color: #2977FF;
	--primary-color-light: #0086FF;
	--primaryhover-color: #D5E6F9;
	/*12%*/
	--primary-color-shadow: #E0F0FF;
	--secondary-color: #F2F4F8;
	--secondary-color-dark: #E7EBEE;
	--textdark-color: #2E3A4B;
	/* #2E3A4B / 100% */
	--text-color: #6D7681;
	--textdark-color-desabled: rgba(46, 58, 75, 0.3);
	--textlight-color: #ffffff;
	--bgsecondary-color: #ffffff;
	--bgtextbtn-color: #ffffff;
	--error-color: #E94F37;
	--success-color: #25BD5D;
	--warn-color: #F5B700;
	--fieldhover-color: #69727D;
	/* #2E3A4B / 70% */
	--field-color: #90969E;
	/* #2E3A4B / 50% */
	--fielddisabled-color: #B8BBC0;
	/* #2E3A4B / 30% */

	/*Tostify */
	--toastify-toast-min-height: 40px;

	/*icons*/
	--battery-color: #2E3A4B;


	--mobile-width: 575.5px;
	--tablet-width: 991.5px;
}

.page {
	padding: 15px 20px;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	overflow-y: hidden;
}

.page__container {
	padding: 17px 64px 100px 64px;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.page__header-pc {
	display: flex;
	align-items: center;
	width: 100%;
	overflow: hidden;
}

.page__header-inside-wrap {
	display: flex;
	width: auto;
	overflow: hidden;
}



/*.page__container::-webkit-scrollbar {
	width: 6px;
	cursor: pointer;
}

.page__container::-webkit-scrollbar-track {
	background: transparent;
	cursor: pointer;
}

.page__container::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background: var(--fielddisabled-color);
	cursor: pointer;
}*/

.page__container::-webkit-scrollbar {
	width: 6px;
	cursor: pointer;
}

.page__container::-webkit-scrollbar-track {
	background: transparent;
	cursor: pointer;
}

.page__container::-webkit-scrollbar-thumb {
	border-radius: 6px;
	background: var(--fielddisabled-color);
	cursor: pointer;
}



/* Firefox */
.page__container {
	scrollbar-width: thin;
	scrollbar-color: var(--fielddisabled-color) transparent;
}

/* Internet Explorer 11 */
.page__container {
	scrollbar-face-color: var(--fielddisabled-color);
	scrollbar-track-color: transparent;
}

/* Microsoft Edge */
.page__container {
	scrollbar-color: var(--fielddisabled-color) transparent;
}

@media (max-width: 1200px) {
	.page__container {
		padding: 17px 32px 100px 32px;
	}
}

@media (max-width: 991.5px) {
	.page__container {
		overflow-y: initial;
		min-height: 600px;
	}
}

@media (max-width: 540px) {
	.page__container {
		padding: 17px 16px 24px 16px;
	}
}

.account__container {
	margin: 0 auto;
	padding: 16px 0;
	max-width: 392px;
	width: 100%;

}

.link {
	color: var(--primary-color);
	text-decoration: none;
	transition: all 0.2s ease-in-out;
}

.link:hover,
.link:hover > i::before,
.link:hover > i::after {
	color: var(--primary-hover-color);
}

.link-dark {
	color: var(--textdark-color);
	text-decoration: none;
	transition: all 0.2s ease-in-out;
}

.link-dark:hover,
.link-dark:hover > i::before,
.link-dark:hover > i::after {
	color: var(--primary-hover-color);
}

.buttons {
	display: flex;
	justify-content: center;
	align-items: center;
}

.buttons > button {
	margin: 0 12px;
}


.no-select {
	-webkit-user-select: none;       /* Chrome, Safari, Opera */
	-moz-user-select: none;          /* Firefox */
	-ms-user-select: none;           /* Internet Explorer и Edge */
	user-select: none;               /* Стандартное свойство */
	-webkit-touch-callout: none;     /* Отключает всплывающее меню на iOS */
	-webkit-tap-highlight-color: transparent; /* Убирает подсветку при нажатии */
	touch-action: manipulation;      /* Предотвращает задержку отклика на касание */
}



.Toastify__toast-theme--colored.Toastify__toast--success {
	background: var(--success-color);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
	background: var(--error-color);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
	background: var(--warn-color);
	color: var(--textdark-color);
}

.Toastify__toast-body {
	padding: 0 16px;
}

.Toastify__toast-icon {
	display: none;
}

.Toastify__toast-container {
	bottom: 16px !important;
	position: bottom-right;
	width: auto !important; 
	max-width: 90vw !important; 
}

.Toastify__toast-body {
	max-width: 100%;
	word-wrap: normal;
    overflow: hidden;
    white-space: nowrap; /* Запобігає переносу тексту */
}

@media (max-width: 768px) {
	.Toastify__toast-container--bottom-center {
		bottom: 60px;
	}

	.Toastify__toast-container {
		width: 80vw;
		left: 50%;
		transform: translateX(-50%);
		bottom: 24px !important;
	}

}

/*player*/

.vjs-button-vr {
	display: none !important;
}

.no-play-button .vjs-big-play-button {
	display: none !important;
}

.no-play-button .vjs-progress-holder {
	height: 4px;
}

.no-play-button .vjs-slider {
	background-color: rgba(255, 255, 255, 0.4);
	border-radius: 8px;
}

.no-play-button .vjs-play-progress {
	background-color: var(--primary-color);
}

@media (max-width: 767.5px) {
	.no-play-button .vjs-volume-panel {
		display: none !important;
	}

	.no-play-button .vjs-control-bar {
		height: auto;
		background: transparent;
	}

	.no-play-button .vjs-progress-control .vjs-progress-holder,
	.no-play-button .vjs-slider {
		margin: 0;
	}

	.no-play-button .vjs-play-progress:before {
		top: -5px;
		font-size: 15px;
		color: var(--primary-color);
	}

	.video-js.vjs-layout-small.no-play-button .vjs-remaining-time,
	.no-play-button .vjs-remaining-time {
		display: flex !important;
		position: absolute !important;
		top: -30px;
		right: 0px;
	}
}

.no-play-button .vjs-remaining-time {
	font-size: 16px;
	line-height: 16px;


}

.video-js .vjs-picture-in-picture-control {
	display: none !important;
}

@media (min-width: 768px) {

	.no-play-button {
		overflow: hidden;
	}

	.no-play-button .vjs-control-bar .vjs-fullscreen-control,
	.no-play-button .vjs-control-bar .vjs-play-control {
		display: none !important;
	}

	.no-play-button .vjs-control-bar {
		bottom: 17px;
		height: 50px;
		padding: 0 24px;
		flex-wrap: wrap;
		background: transparent;
	}

	.no-play-button .vjs-progress-control {
		display: block;
		flex-basis: 100%;
		flex-shrink: 0;
		height: 24px;
	}

	.no-play-button .vjs-play-progress:before {
		top: -2px;
		font-size: 9px;
	}

	.no-play-button .vjs-slider:focus {
		text-shadow: none;
		box-shadow: none;
	}

	.no-play-button .vjs-volume-panel .vjs-icon-placeholder:before {
		font-size: 20px;
		line-height: 16px;
	}

	.no-play-button .vjs-volume-bar {
		margin: 6px 0 0 0 !important;
	}



	.no-play-button .vjs-volume-panel .vjs-volume-control {
		opacity: 1 !important;
		width: 51px !important;

	}

	.no-play-button .vjs-volume-panel .vjs-volume-level {
		height: 4px !important;
		border-radius: 8px !important;
	}

	.no-play-button .vjs-volume-level:before {
		top: -2px;
		right: -1px;
		font-size: 10px;
	}

}


/*dnd playlist*/

/*[data-rbd-droppable-id="droppable"] {
	overflow-y: scroll;
	max-height: calc(100% - 35px);
	position: 'relative';
}

[data-rbd-droppable-id="droppable"]::-webkit-scrollbar {
	width: 4px;
	cursor: pointer;
}

[data-rbd-droppable-id="droppable"]::-webkit-scrollbar-track {
	background: transparent;
	cursor: pointer;
}

[data-rbd-droppable-id="droppable"]::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--fielddisabled-color);
	cursor: pointer;
}*/

[data-rbd-droppable-id="droppable"] {
	overflow-y: scroll;
	max-height: calc(100% - 35px);
	position: 'relative';
	/* Note: Removed the comma after 'relative' */
	/* Add other non-prefixed properties here */
}

/* WebKit (Safari, Chrome) */
[data-rbd-droppable-id="droppable"]::-webkit-scrollbar {
	width: 4px;
	cursor: pointer;
}

[data-rbd-droppable-id="droppable"]::-webkit-scrollbar-track {
	background: transparent;
	cursor: pointer;
}

[data-rbd-droppable-id="droppable"]::-webkit-scrollbar-thumb {
	border-radius: 4px;
	background: var(--fielddisabled-color);
	cursor: pointer;
}

/* Firefox */
[data-rbd-droppable-id="droppable"] {
	scrollbar-width: thin;
	scrollbar-color: var(--fielddisabled-color) transparent;
}

/* Internet Explorer 11 */
[data-rbd-droppable-id="droppable"] {
	scrollbar-face-color: var(--fielddisabled-color);
	scrollbar-track-color: transparent;
}

/* Microsoft Edge */
[data-rbd-droppable-id="droppable"] {
	scrollbar-color: var(--fielddisabled-color) transparent;
}

/*hide text in select options*/

.hideInInput__text {
	display: inline-block;
}

.select__single-value .hide__text {
	display: none;
}

.show__text {
	display: none;
}

.select__single-value .show__text {
	display: inline-block;
}

/*player*/



.cr__player .vjs-time-control {
	order: 4;
	flex-basis: 20%;
	margin-top: -2px;
}

.cr__player .vjs-volume-panel {
	order: 2;
	flex-grow: 1 !important;
}

.cr__player .player-buttons-container {
	display: flex;
	align-items: flex-start;
	order: 1;
	margin-top: -5px;
}

.cr__player .player-next-button,
.cr__player .player-prev-button {
	cursor: pointer;
	height: auto !important;
	line-height: 16px !important;
}

.cr__player .player-prev-button::before {
	content: url('../assets/icons/prev.svg');
}

.cr__player .player-next-button::before {
	content: url('../assets/icons/next.svg');
}


@media (max-width: 991.5px) {
	.cr__player {
		position: relative;
	}

	.cr__player .player-buttons-container {
		position: absolute;
		top: -120px;
		left: 0;
		right: 0;
		justify-content: space-between;
	}
}


.rc-slider-handle {
	width: 16px !important;
	height: 16px !important;
	margin-top: -6px !important;
	margin-left: -8px !important;
	border-radius: 50%;
	border: 2px solid var(--primary-color) !important;
	background-color: var(--primary-color) !important;
}

.rc-slider-track {
	background-color: var(--primary-color) !important;
}

.rc-slider-rail {
	background-color: var(--fielddisabled-color) !important;
}

.rc-slider-dot:nth-child(n) {
	border: none !important;
	border-radius: 0 !important;
	width: 6px !important;
	margin-left: -3px !important;
}

.rc-slider-dot:first-child {
	background-color: var(--primary-color) !important;
	height: 4px !important;
	top: 0 !important;
}

.custom-tooltipe {
	position: absolute;
	top: -30px;
	left: 50%;
	transform: translate(-50%, 0%);
	width: auto;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 4px;
	padding: 2px 4px;
	background-color: var(--textdark-color);
	color: rgba(255, 255, 255, 0.7);
	/*visibility: hidden;*/
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}

.rc-slider-handle:hover .custom-tooltipe,
.rc-slider-handle:focus .custom-tooltipe,
.rc-slider-handle:active .custom-tooltipe {
	/*visibility: visible;*/
	transition: opacity 0.2s ease-in-out;
	opacity: 1;
}

.rc-slider-mark {
	font-size: 16px !important;
}

.video-responsive {
	height: 100%;
}

.video-responsive>iframe {
	width: 100%;
	height: 100%;
}

/*payment modal*/

#f .f-merchant-name {
	display: none;
}

#f .f-merchant-url {
	display: none;
}

#f .f-order-desc {
	display: none;
}

[class^="mode_wrapper"] {
	display: none !important;
}

.f-price {
	display: none !important;

}

#f .f-icons {
	display: none !important;
}

#f .f-card-bg {
	background: linear-gradient(129deg, #405777 13.94%, #2E3A4B 89.23%) !important;
}

#f .f-card_label {
	font-family: 'Inter', sans-serif !important;
}

#f .f-form-group-card .f-form-control {
	font-family: 'Inter', sans-serif !important;
	font-size: 20px !important;
	font-weight: 600 !important;
	letter-spacing: 0.7px !important;
}

#f .f-form-control[name=f-expiry_date] {
	font-family: 'Inter', sans-serif !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	letter-spacing: 0.56px !important;
}

#f .f-form-control[name=f-cvv2] {
	-webkit-text-security: disc;
	/* For Safari, Chrome, and newer browsers */
	text-security: disc;
	/* For Firefox, Edge, and newer browsers */
}

.f-form-control.f-form-control-default.f-checkout-email {
	border-radius: 6px !important;
}

.f-security.f-center-security {
	display: none !important;
}

html #f.f-embed .f-wrapper {
	min-height: auto !important;
}

#f .f-center-wrap {
	padding: 0 !important;
}

#f .f-container-sm {
	padding: 0 !important;
	max-width: 100% !important;
}

#f .f-card-shadow {
	box-shadow: none !important;
}

.f-form-control.f-form-control-default.f-checkout-email {
	color: var(--textdark-color) !important;
}

.f-card__devider {
	display: block !important;
	margin: 0 auto 12px auto !important;
	text-align: center !important;
}

#f .f-center .f-wallet-pay-button {
	margin-bottom: 12px !important;
}

#f .f-modal-content {
	max-width: 550px !important;
	width: 90% !important;
	text-align: center !important;
}

#f .f-modal-dialog {
	justify-content: center !important;
}

#f .f-modal-header .f-close {
	right: -1rem !important;
	top: -4rem !important;
}

#checkout-container.hide__orderid .f-success-form-group {
	display: none !important;
	-webkit-display: none !important;
	/* Для Webkit (Chrome, Safari) */
	-moz-display: none !important;
	/* Для Mozilla Firefox */
	-ms-display: none !important;
	/* Для Internet Explorer */
	-o-display: none !important;
	/* Для Opera */
}

.insights__digit {
	color: var(--textdark-color);
	cursor: default;
	cursor: inherit;
	font-size: 25px;
	font-weight: 600;
}

.insights__letter {
	font-weight: 400;
	font-size: 16px;
	color: var(--field-color);
	cursor: default;
	cursor: inherit;
}

.select__single-value {
	color: var(--textdark-color) !important;
}

.back_title h3 {
	color: var(--fieldhover-color);
}

/*joyride*/

@keyframes fadeIn {
	from {
	  opacity: 0;
	  /*transform: translateX(-100%);*/
	  /*transform: translateY(-100%);*/
	}
	to {
	  opacity: 1;
	  /*transform: translate(0);*/
	}
 }

.__floater__arrow svg polygon {
	fill: var(--textdark-color) !important;
	animation: fadeIn 0.5s ease-in-out forwards;
}

.react-joyride__spotlight {
	left: -20px !important;
	/*border: 3px solid var(--primary-color);*/
	border-radius: 8px !important;
	/*outline: green;
	outline-width: 4px;
	outline-style: solid;*/

}

.react-joyride__overlay {
	/*background-color: rgba(0, 0, 0, 0.6) !important;*/
	background-color: #979DA5;

}

.react-joyride__beacon > span:nth-child(1) {
	background-color: var(--primary-color-light) !important;
}

.react-joyride__beacon > span:nth-child(2) {
	background-color: transparent !important;
	border-color: var(--primary-color-light) !important;
}



  