.container {
	display: flex;
	flex-direction: column;
	/*height: calc(100vh - 75px);
	overflow: hidden;*/
	height: 100%;
	position: relative;
}
.container p {
	margin: 0px 0px 0px 16px;
	color: var(--textdark-color);
}
.filter {
	margin: 0px 0px 28px 0px;
}

.options {
	padding: 16px 16px 16px 16px;
	flex-shrink: 0;
	flex-grow: 1;
	/*overflow-y: auto;*/
}

.btns {
	padding: 8px;
	border-top: 0.5px solid rgba(46, 58, 75, 0.3);
	background-color: #fff;
	display: flex;
	justify-content:center;
	align-items: center;
	position: sticky;
	bottom: 0px;
}

.btns > button {
	margin: 0px 12px;
}