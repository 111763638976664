.column__id {
	display: flex;
}

.tooltip__info {
	display: inline-flex;
	margin: 0px 0px 0px 4px;

}

.tooltip__info i {
	font-size: 20px;
	color: var(--field-color);
	cursor: pointer;
}

.tooltip__info i:hover {
	color: var(--primary-color);
}

.button i::before {
	font-size: 18px;
}