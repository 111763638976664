.title__wrapper p {
	color: #2E3A4B;
	font-weight: 500px;
	padding-top: 20px;
}

.text__wrapper p {
	text-align: center;
	padding-top: 12px;
}

.container__row {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 112px;
	width: 328px;
	margin: 0 auto;
}

.img__wrapper img {
	width: 208px;
	height: 110px;
	object-fit: cover;
}
